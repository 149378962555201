<template>
  <div style="border-radius: 8px" class="row">
    <div class="row">
      <div class="card p-2 pb-0 filter col-md-8">
        <div class="row w-100 pe-lg-0 me-1 py-2">
          <div class="col-md-3 col-12">
            <div class="mb-1">
              <div class="form-check">
                <input v-model="checkMark.full_name" class="form-check-input" id="full_name"
                       type="checkbox" tabindex="3"/>
                <label class="form-check-label" for="full_name"> Name</label>
              </div>
            </div>
          </div>
          <div class="col-md-3 col-12">
            <div class="mb-1">
              <div class="form-check">
                <input v-model="checkMark.bill_number" class="form-check-input" id="bill_number"
                       type="checkbox" tabindex="3"/>
                <label class="form-check-label" for="bill_number"> Bill Number</label>
              </div>
            </div>
          </div>
          <div class="col-md-3 col-12">
            <div class="mb-1">
              <label for="quantity">Quantity</label>
              <input v-model="sticker.quantity" class="form-control" id="quantity" type="number"/>
            </div>
          </div>
          <div class="col-md-3 col-12">
            <div class="mb-1">
              <button onclick="window.print()" class="btn btn-primary mt-1">Print</button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div id="section-to-print">
          <div v-for="index in barcodeQty" :key="index" class="sticker-border text-center loop-dev">
            <div style="display:block;vertical-align:middle;line-height:15px !important;">
              <span v-if="checkMark.full_name" class="text-label fixed-width-name">{{ sticker.full_name }}</span>
              <span v-if="checkMark.bill_number" class="text-label">{{ sticker.bill_number }}</span>
              <div v-html="barcode"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>

import {computed, onMounted, ref} from "vue";
import {useRoute} from "vue-router";
import handleBarcode from "@/services/modules/barcode";

const {fetchBarcode} = handleBarcode();
const route = useRoute();

let companyId = computed(() => route.params.companyId);
const barcodeQty = computed(() => parseInt(sticker.value.quantity));

let barcode = ref(null);
let sticker = ref({
  quantity: 1,
  full_name: '',
  bill_number: '',
});

let checkMark = ref({
  full_name: true,
  bill_number: true,
})

const getBarCode = (code) => {
  fetchBarcode(`?code=${code}&qrcode=${code}&width=2&height=35`).then((res) => {
    barcode.value = res.barcode;
  })
}

onMounted(() => {
  if (route.query.barcode) {
    getBarCode(route.query.barcode)
  }
  sticker.value.bill_number = route.query.bill_number
  sticker.value.full_name = route.query.party_name
})

</script>

<style>
.text-center {
  text-align: center;
}

.loop-dev {
  height: auto;
  padding-bottom: 5px;
  margin-bottom: 10px;
  line-height: 1.0000in;
  width: 1.5in !important;
  display: block;
}

.text-label {
  font-size: 14px;
  display: block;
  margin: 3px
}

/*Css related to printing of barcode*/
.label-border-outer {
  line-height: 16px;
  page-break-after: always;
  border: 0.1px solid grey !important;
}

.label-border-internal {
  margin: 24px;
}

.sticker-border {
  border: 0.1px dotted grey !important;
  overflow: hidden;
  box-sizing: border-box;
}

.fixed-width-name {
  width: 140px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@media print {
  body {
    visibility: hidden;
  }

  #section-to-print {
    visibility: visible;
    position: absolute;
    left: 0;
    top: 0;
  }

  .sticker-border {
    border: none !important;
  }

  @page {
    margin: 0;
  }
}

@page {
  size: 1in 0.6in;

}
</style>
